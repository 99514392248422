import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

// pages
import Login from "../screens/auth/login";
import Home from "../screens/home";
import PushNotificationList from "../screens/push-notifications/push-notification-list";
import PushNotificationAdd from "../screens/push-notifications/push-notification-add";
import PushNotificationDetails from "../screens/push-notifications/push-notification-details";
import SurveyList from "../screens/survey/survey-list";
import ChangePassword from "../screens/change-password";
import Components from "../screens/components";

function AppRoutes() {
  const [isAuthenticated] = useState(true);
  return (
    <Switch>
      {/* <Route exact path="/"></Route> */}
      {/* <Route
        exact
        path="/components"
        isAuthenticated={isAuthenticated}
        component={Components}
      /> */}
      <Route
        exact
        path="/"
        isAuthenticated={isAuthenticated}
        component={Login}
      />
      <Route
        exact
        path="/home"
        isAuthenticated={isAuthenticated}
        component={Home}
      />
      <Route
        exact
        path="/push-notification/list"
        isAuthenticated={isAuthenticated}
        component={PushNotificationList}
      />
      <Route
        exact
        path="/push-notification/add"
        isAuthenticated={isAuthenticated}
        component={PushNotificationAdd}
      />
      <Route
        exact
        path="/push-notification/edit/:notificationId"
        isAuthenticated={isAuthenticated}
        component={PushNotificationAdd}
      />
      <Route
        exact
        path="/push-notification/details"
        isAuthenticated={isAuthenticated}
        component={PushNotificationDetails}
      />

      <Route
        exact
        path="/survey/list"
        isAuthenticated={isAuthenticated}
        component={SurveyList}
      />
      {/* <Route
        exact
        path="/change-password"
        isAuthenticated={isAuthenticated}
        component={ChangePassword}
      /> */}
    </Switch>
  );
}

export default AppRoutes;
