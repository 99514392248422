import React from 'react';
import PropTypes from 'prop-types';

// framework
import { Image } from 'react-bootstrap';

// styles
import './avtar.scss';

const Avtar = (props) => {
	return (
		<div className={`${props.containerStyle} avtar-wrap`} style={{ width: props.size, height: props.size }}>
			<Image
				className={props.style}
				roundedCircle={true}
				src={props.src}
			/>
		</div>
	)
}


/**
 * containerStyle: provide class to main wrapper
 * src: set the source
 * style: provide class to image
 * size: set avtar size width and height is equal
 */

Avtar.propTypes = {
	containerStyle: PropTypes.string,
	src: PropTypes.string,
	style: PropTypes.string,
	size: PropTypes.number,
};

Avtar.defaultProps = {
	containerStyle: '',
	src: '',
	style: '',
	size: 50,
}

export default Avtar;