import React, { useState } from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

// components
import { NavHeader, NavFooter } from "./components";

// screens
import Login from "./screens/auth/login";
import AppRoutes from "./navigation/app-routes";

// utils
import { APP_FEATURE } from "./utils/service";
import { isAuthenticated } from "./utils/helpers/Authentication";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/index.scss";

// Environment
import { ENVIRONMENT } from "./utils/config/constants";

if (ENVIRONMENT === "PRODUCTION") {
    console.log = function () {};
}

function App() {
    return (
        <Router>
            {!isAuthenticated() ? (
                <React.Fragment>
                    <Redirect to="/"></Redirect>
                    <Login></Login>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <NavHeader navData={APP_FEATURE} />
                    <AppRoutes />
                    <NavFooter />
                </React.Fragment>
            )}
        </Router>
    );
}

export default App;
