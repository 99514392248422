import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";

// framework
import { Form } from "react-bootstrap";

// plugin
import JoditEditor from "jodit-react";

// style
import "./editor.scss";

const Editor = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState(props.defaultValue);

  const [count, setCount] = useState(props.defaultValue.length);

  // editor config
  const config = {
    // defaultMode: 1,
    readonly: props.readOnly,
    height: 400,
    disablePlugins: "powered-by-jodit, sticky",
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: props.placeholder,
    limitChars: 500,
    /* uploader: {
      insertImageAsBase64URI: true
    }, */
    uploader: {
      url: "http://localhost:8181/index-test.php?action=fileUpload",
    },
    filebrowser: {
      ajax: {
        url: "http://localhost:8181/index-test.php",
      },
    },
    // beautyHTML: true,
    // showCharsCounter: true,
    // "showWordsCounter": false,
    // "showXPathInStatusbar": false
  };
  //const characterCount = (event) => {
  //   console.log(event.length, "EVENT", props.maxLength);
  // var regex = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
  //   result = event.replace(regex, "");

  //   console.log(result.length, "result", props.maxLength - result.length);
  //   console.log(setCount, "set count");
  //setCount(props.maxLength - result.length);
  //};

  // handle on change of input
  const handleChange = (editorContent) => {
    // let modifiedContent = `<!DOCTYPE HTML> <html><head><meta charset="utf-8" /><title></title></head> <body>${editorContent.trim()}</body> </html>`;

    // console.log("JoditEditor handleChange", editorContent);
    props.onChange(editorContent);
    setContent(editorContent);

    // if (props.showLength) {
    //   characterCount(editorContent);
    // }
  };
  const Edit = useMemo(() => {
    return (
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        // onBlur={handleChange}
        onChange={handleChange}
      />
    );
  }, []);

  return (
    <Form.Group
      controlId={props.id}
      className={`${props.containerStyle} ${
        props.readOnly && "form-readonly"
      } ${props.isInvalid && "form-invalid"} form-group`}
    >
      {props.label && (
        <Form.Label
          className={`${props.labelStyle} ${
            props.showLength ? "form-character-limit" : ""
          }`}
        >
          {props.label}
          {/* {props.showLength && (
            <span>
              {count} out of {props.maxLength} Characters left
            </span>
          )} */}
        </Form.Label>
      )}

      <div className="editor-wrap">
        {/* <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onBlur={handleChange}
                    // onChange={handleChange}
                /> */}
        {Edit}
      </div>
      {props.helperText && (
        <Form.Control.Feedback
          type={`${props.isValid && "valid"} ${props.isInvalid && "invalid"}`}
        >
          {props.helperText}
        </Form.Control.Feedback>
      )}
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
    </Form.Group>
  );
};

/**
 * label: set label text
 * id :  set element id
 * placeholder: placeholder of input
 * defaultValue: set defaultValue
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * required : set is required or not
 * onBlur: handlers from firing regardless of the rendered element.
 */

Editor.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
};

Editor.defaultProps = {
  label: "",
  id: "",
  placeholder: "",
  defaultValue: "",
  style: "",
  labelStyle: "",
  containerStyle: "",
  helperText: "",
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  onBlur: () => {},
};

export default Editor;
