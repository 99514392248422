import React from "react";
import PropTypes from "prop-types";

// framework
import { Pagination } from "react-bootstrap";

// styles
import "./paginator.scss";

// utils
// import { APP_FEATURE } from '../../../utils/service'

const Paginator = () => {
  return (
    <div className="paginator-wrap">
      <Pagination>
        {/* <Pagination.First /> */}
        {/* <Pagination.Prev className="page-prev" />
				<Pagination.Item>{1}</Pagination.Item>
				<Pagination.Ellipsis />

				<Pagination.Item>{10}</Pagination.Item>
				<Pagination.Item active>{11}</Pagination.Item>
				<Pagination.Item >{12}</Pagination.Item>

				<Pagination.Ellipsis />
				<Pagination.Item>{20}</Pagination.Item>
				<Pagination.Next className="page-next" /> */}
        {/* <Pagination.Last /> */}
      </Pagination>
    </div>
  );
};

Paginator.propTypes = {};

Paginator.defaultProps = {};

export default Paginator;
