import axios from "axios";
import { getSessionToken } from "../Authentication";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in DOWNLOAD_CSV:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const downloadSurveyCSV = (surveyId) => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/downloadcsv/survey?surveyId=` + surveyId,
        responseType: "blob",
        headers: {
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
    });
};

// const downloadHealthCSV = () => {
//   return axios({
//     method: "GET",
//     url: `${URL["BASE_URL_" + ENVIRONMENT]}/downloadcsv/health`,
//     responseType: "blob",
//     headers: {
//       Authorization: `Bearer ${getSessionToken()}`,
//     },
//     validateStatus: false,
//   });
// };

export {
    downloadSurveyCSV,
    //  downloadHealthCSV
};
