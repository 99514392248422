import React, { useState } from 'react';
import PropTypes from 'prop-types';

// framework
import { Form } from 'react-bootstrap';

const Input = (props) => {

	const [count, setCount] = useState(props.defaultValue.length);

	// counter for maxLength
	const characterCount = (event) => {
		setCount(props.maxLength - event.target.value.length);
	}

	// handle on change of input
	const handleChange = (event) => {
		props.onChange(event)

		if (props.showLength) {
			characterCount(event)
		}
	};

	// handle on change of input
	const handleBlur = (event) => {
		// console.log("event", event)
		props.onBlur(event)
	};


	return (
		<Form.Group controlId={props.id} className={`${props.containerStyle} form-group`}>
			{props.label && <Form.Label className={`${props.labelStyle} ${props.showLength ? 'form-character-limit' : ''}`}>
				{props.label}
				{
					props.showLength && (
						<span>{count} out of {props.maxLength} Characters left</span>
					)
				}

			</Form.Label>}
			<div className="form-control-wrap">
				<Form.Control
					required={props.required}
					type={props.type}
					placeholder={props.placeholder}
					defaultValue={props.defaultValue}
					disabled={props.disabled}
					isValid={props.isValid}
					isInvalid={props.isInvalid}
					readOnly={props.readOnly}
					size={props.size}
					onChange={handleChange}
					onBlur={handleBlur}
					className={`${props.style}`}
					plaintext={props.plaintext}
					as={props.as}
					rows={props.rows}
					maxLength={props.maxLength}
					name={props.id}
					value={props.value}
				/>
				{
					props.helperText && <Form.Control.Feedback type={`${props.isValid && 'valid'} ${props.isInvalid && 'invalid'}`}>{props.helperText}</Form.Control.Feedback>
				}
			</div>
		</Form.Group>
	)
}

/**
	* as: The underlying HTML element to use when rendering the FormControl. 'input' | 'textarea' 
	* label: set label text
	* id: set element id 
	* name: set element name 
	* type: set the type of input
	* placeholder: placeholder of input 
	* defaultValue: set defaultValue
	* size: Input size variants 'sm' | 'lg'
	* style: Manually style the control
	* labelStyle: Manually style the label
	* containerStyle: Manually style the wrapper
	* helperText:  providing helper text 
	* rows: set rows size for textarea
	* maxLength: set maxLength of input 
	* disabled: Make the control disabled
	* isValid : Manually style the input as valid
	* isInvalid : Manually style the input as invalid
	* readOnly : Readlonly element
	* required : set is required or not
	* showLength : show character count
	* plaintext: Render the input as plain text. Generally used along side readOnly.
	* onChange: handlers from firing regardless of the rendered element.
	* onBlur: handlers from firing regardless of the rendered element.
*/


Input.propTypes = {
	as: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.string,
	labelStyle: PropTypes.string,
	containerStyle: PropTypes.string,
	helperText: PropTypes.string,
	rows: PropTypes.number,
	maxLength: PropTypes.number,
	disabled: PropTypes.bool,
	isValid: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	isInvalid: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	showLength: PropTypes.bool,
	plaintext: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func
};

Input.defaultProps = {
	as: 'input',
	label: '',
	id: '',
	type: 'text',
	placeholder: '',
	defaultValue: '',
	size: '',
	style: '',
	labelStyle: '',
	containerStyle: '',
	helperText: '',
	rows: null,
	maxLength: null,
	disabled: false,
	isValid: false,
	isInvalid: false,
	readOnly: false,
	required: false,
	showLength: false,
	plaintext: false,
	onChange: () => { },
	onBlur: () => { },
}

export default Input