import React, {useState} from 'react';
import PropTypes from 'prop-types';

// framework
import { Form } from 'react-bootstrap';

// plugin
import ReactCrop from 'react-image-crop';

// assets
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import 'react-image-crop/dist/ReactCrop.css';

// component
import { DisplayCoverPicture, Btn, Dialog } from '../../../components';



const UploadCoverPicture = (props) => {

	const [changePhotoModal, setChangePhotoModal] = useState(false);

	const [imageRef, setImageRef] = useState(null);
	const [fileURL, setFileURL] = useState("");
	const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState(props.cropConfig);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const [_croppedImageUrl, seTempCroppedImageUrl] = useState(null);

	// handle on change of input
	/* const handleChange = (event) => {
		props.onChange(event)
	}; */

	// image crop con
	function handleChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
				setImgSrc(reader.result)
      );

			setChangePhotoModal(true)
      reader.readAsDataURL(e.target.files[0]);
    }
  }


	// If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = async (crop) => {
    await makeClientCrop(crop);

  };

	const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

	async function makeClientCrop(crop) {
		// console.log(crop, imageRef);
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      seTempCroppedImageUrl(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

		// console.log(canvas.toDataURL());

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
					console.log(blob);
          blob.name = fileName;
          window.URL.revokeObjectURL(fileURL);
          setFileURL(window.URL.createObjectURL(blob));
          resolve(window.URL.createObjectURL(blob));
        },
        'image/jpeg',
        1
      );
    });
  }

	const uploadCropImage = () => {
		setImgSrc(null)
		setCroppedImageUrl(_croppedImageUrl); 
		props.onChange(_croppedImageUrl);
		setChangePhotoModal(false);
	}


	return (
		<Form.Group controlId={props.id} className={`${props.containerStyle} form-group`}>
			{props.label && <Form.Label className={`${props.labelStyle}`}>{props.label}</Form.Label>}
			<div>
				<Form.Label className="mb-0">
					<DisplayCoverPicture
						src={croppedImageUrl ? croppedImageUrl : (props.src == "" ? GLOBAL_IMAGES.DUMMY_COVER_IMAGE : props.src)}
						fluid={props.fluid}
						rounded={props.rounded}
						roundedCircle={props.roundedCircle}
						thumbnail={props.thumbnail}
						caption={props.caption}
					/>
					<Form.Control
						required={props.required}
						type={props.type}
						placeholder={props.placeholder}
						defaultValue={props.defaultValue}
						disabled={props.disabled}
						isValid={props.isValid}
						isInvalid={props.isInvalid}
						readOnly={props.readOnly}
						size={props.size}
						onChange={handleChange}
						className={`${props.style} btn`}
						plaintext={props.plaintext}
						as={props.as}
						rows={props.rows}
						maxLength={props.maxLength}
						accept={props.accept}
						hidden
					/>
					<Btn
						variant="outline-primary"
						size="lg"
						label={props.src == "" ? "Attach Photo" : "Change Photo"}
						for={props.id}
						as="label"
					/>
				</Form.Label>
			</div>

			<Dialog
					title={"Delete Banner"}
					description={imgSrc && (
						<ReactCrop
							src={imgSrc}
							crop={crop}
							locked={props.isCropLocked}
							ruleOfThirds
							onImageLoaded={onImageLoaded}
							 onComplete={onCropComplete}
							onChange={onCropChange}
						/>
					)}
					centered={true}
					show={changePhotoModal}
					close={(val) => { setChangePhotoModal(val) }}
					actions={
						<div className="btn-wrap d-flex justify-content-end">
							<Btn
								variant="secondary"
								label="Cancel"
								onClick={() => { setChangePhotoModal(false); setImgSrc(null) }}
							/>
							<Btn
								variant="primary"
								label="Save"
								onClick={() => { uploadCropImage() }}
							/>
						</div>
					}
				/>
			<Form.Control.Feedback type={`${props.isValid && 'valid'} ${props.isInvalid && 'invalid'}`}>{props.helperText}</Form.Control.Feedback>
		</Form.Group>
	)
}

/**
	* src: source of an image
	* cpation: set caption
	* style: Manually style the image
	* containerStyle: Manually style the wrapper
	* fluid: Sets image as fluid image.
	* rounded: Sets image shape as rounded.
	* roundedCircle: Sets image shape as circle.
	* thumbnail: Sets image shape as thumbnail.
	* as: The underlying HTML element to use when rendering the FormControl. 'input' | 'textarea' 
	* label: set label text
	* id: set element id 
	* type: set the type of input
	* placeholder: placeholder of input 
	* defaultValue: set defaultValue
	* size: Input size variants 'sm' | 'lg'
	* style: Manually style the control
	* labelStyle: Manually style the label
	* containerStyle: Manually style the wrapper
	* helperText:  providing helper text 
	* rows: set rows size for textarea
	* maxLength: set maxLength of input 
	* disabled: Make the control disabled
	* isValid : Manually style the input as valid
	* isInvalid : Manually style the input as invalid
	* readOnly : Readlonly element
	* required : set is required or not
	* plaintext: Render the input as plain text. Generally used along side readOnly.
	* onChange: handlers from firing regardless of the rendered element.
	* cropConfig: set the crop config
	* isCropLocked: set the crop resize as locked
*/

UploadCoverPicture.propTypes = {
	as: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	type: PropTypes.string,
	src: PropTypes.string,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.string,
	labelStyle: PropTypes.string,
	containerStyle: PropTypes.string,
	helperText: PropTypes.string,
	accept: PropTypes.string,
	rows: PropTypes.number,
	maxLength: PropTypes.number,
	disabled: PropTypes.bool,
	isValid: PropTypes.bool,
	isInvalid: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	plaintext: PropTypes.bool,
	onChange: PropTypes.func,
	cropConfig: PropTypes.object,
	isCropLocked: PropTypes.bool,
};

UploadCoverPicture.defaultProps = {
	as: 'input',
	label: '',
	id: '',
	type: 'file',
	placeholder: '',
	src: '',
	defaultValue: '',
	size: '',
	style: '',
	labelStyle: '',
	containerStyle: '',
	helperText: '',
	accept: 'image/gif, image/jpeg, image/png',
	rows: null,
	maxLength: null,
	disabled: false,
	isValid: false,
	isInvalid: false,
	readOnly: false,
	required: false,
	plaintext: false,
	onChange: () => { },
	cropConfig: {
		x: 25,
		y: 25,
		unit: '%',
		width: 50,
		aspect: 1 / 1
	},
	isCropLocked: true
}

export default UploadCoverPicture