import React, {useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// framework
import {Form, Col} from 'react-bootstrap';

// assets
import { GLOBAL_ICONS } from '../../assets/icons/global-icons';
import Attachment from '../../assets/icons/attachment.png';
// import Pdf from '../../assets/icons/pdf.png';

// component
import {Btn, Avtar} from '../../components'

const InputFile = (props) => {

    const [fileName, setFileName] = useState(props.defaultValue)



    // handle on change of input
    const handleChange = (event) => {

        var pieces = event.target.value.split('\\');
        var filename = pieces[pieces.length-1];
        setFileName(filename)

        props.onChange(event)
    };


    return (
        <Form.Group controlId={props.id} className={`${props.containerStyle} form-group`}>
            { props.label && <Form.Label className={`${props.labelStyle}`}>
                {props.label}
            </Form.Label> }
            <Form.Label className="mb-0 form-input-wrap">
                <div className="form-file-wrap">
                    {
                        props.isAvtar && (
                            <div className="form-attachment-wrap">
                                <Avtar
                                    src={Attachment}
                                    size={70}
                                />
                            </div>
                        )
                    }
                    <div className={`form-file-control ${props.isAvtar ? 'form-fileAvtar-control' : ''}`}>
                        <div className={`${props.isAvtar ? 'form-file-avtar' : 'form-file-noavtar'}`}>
                            <div className={`form-control text-truncate ${props.isInvalid && 'is-invalid'}`}>
                                {fileName == '' ? 'File Name' : fileName}
                            </div>
                            <div className="text-wrap">
                                <p className="caption">{props.caption}</p>
                            </div>
                        </div>
                        <Btn
                            as="label"
                            variant="outline-primary"
                            label="Choose File"
                            for={props.id}
                            style={props.isInvalid ? 'btn-outline-danger' : ''}
                        />
                    </div>
                </div>
            </Form.Label>
            <Form.Control
                required={props.required}
                type="file"
                placeholder={props.placeholder}
                disabled={props.disabled}
                isValid={props.isValid}
                isInvalid={props.isInvalid}
                readOnly={props.readOnly}
                size={props.size}
                onChange={handleChange}
                className={`${props.style}`}
                accept={props.accept}
                hidden
            />  
            {
                props.helperText && (
                    <Form.Control.Feedback type={`${props.isValid && 'valid'} ${props.isInvalid && 'invalid'}`}>{props.helperText}</Form.Control.Feedback>
                )
            }
        </Form.Group>
    )
}

/**
	* label: set label text
	* id: set element id 
	* placeholder: placeholder of input 
	* defaultValue: set defaultValue
	* size: Input size variants 'sm' | 'lg'
	* style: Manually style the control
	* labelStyle: Manually style the label
	* containerStyle: Manually style the wrapper
	* helperText:  providing helper text 
	* caption: set caption for file
	* accept: provide file accept type
	* disabled: Make the control disabled
	* isValid : Manually style the input as valid
	* isInvalid : Manually style the input as invalid
	* readOnly : Readlonly element
	* required : set is required or not
	* onChange: handlers from firing regardless of the rendered element.
	* isAvtar: show file as avtar
*/


InputFile.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.string,
    labelStyle: PropTypes.string,
    containerStyle: PropTypes.string,
    helperText: PropTypes.string,
    caption: PropTypes.string,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    isAvtar: PropTypes.bool,
};

InputFile.defaultProps = {
    label: '',
    id: '',
    placeholder: '',
    defaultValue: '',
    size: '',
    style: '',
    labelStyle: '',
    containerStyle: '',
    helperText: '',
    caption: '',
    accept: '*',
    maxLength: null,
    disabled: false,
    isValid: false,
    isInvalid: false,
    readOnly: false,
    required: false,
    isAvtar: true,
    onChange: () => { },
}

export default InputFile