import axios from "axios";
import { getSessionToken } from "../Authentication";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in SURVEY:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const surveyAPI = () => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/survey/cms`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSessionToken()}`,
        },
        validateStatus: false,
    });
};

export default surveyAPI;
