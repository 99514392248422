import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles
import "./home.scss";

// components
import { FeatureCard } from "../../components";

// utils
import { APP_FEATURE } from "../../utils/service";

// service
// import { navigate } from '../../services/navigation-services';

const Home = () => {
  useEffect(() => {
    // console.log("USER_API", localStorage.getItem("@authToken"));
  }, []);
  const history = useHistory();

  function handleClick(link) {
    history.push({
      pathname: link,
    });
  }

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap login-screen-wrap">
        <Container>
          <Row>
            {APP_FEATURE.map((item, index) => {
              return (
                item.isActive && (
                  <Col sm={6} lg={3} key={index}>
                    {console.log(item.icon, "props.item")}
                    <FeatureCard
                      icon={item.icon}
                      title={item.title}
                      onClick={() => {
                        handleClick(item.link);
                      }}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Home;
