import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import parse from "html-react-parser";
import Spinner from "react-bootstrap/Spinner";
// framework
import { Container, Row, Col, Nav } from "react-bootstrap";

// styles

// components
import {
  Btn,
  Paginator,
  FeatureContentCard,
  BreadCrumb,
  Dialog,
  Legend,
} from "../../../components";

// utils
import {
  SORTBY_OPTIONS,
  PUSHNOTIFICATION_FILTER_OPTIONS,
} from "../../../utils/service";
import { notificationAPI } from "../../../utils/helpers/API/NOTIFICATION_API";
import deleteNotificationAPI from "../../../utils/helpers/API/DELETE_NOTIFICATION";
import {
  redirectToLogin,
  removeCookies,
} from "../../../utils/helpers/Authentication";

const PushNotificationList = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [sortByValue, setSortByValue] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [notificationId, setNotificationId] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [loader, setLoader] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [notificationTab, setNotificationsTab] = useState(
    "triggerNotification"
  );

  const breadcrumb = [
    {
      label: "Push Notifications",
      href: "/push-notification/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  const [modalDelete, setModalDelete] = useState(false);

  const fetchNotifications = async (schedule_type) => {
    try {
      setIsLoading(true);
      const res = await notificationAPI(schedule_type);
      let _data = res.data;
      console.log("response data", _data.status);
      console.log("response data", _data);
      if (_data.status === "Success") {
        setIsLoading(false);
        setNotificationList(_data?.notification.reverse());
      } else if (_data.message === "Unauthorized" && _data.status === "error") {
        removeCookies();
        redirectToLogin();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchNotifications("now");
    setLoader(false);
  }, []);

  useEffect(() => {
    triggerData(notificationTab);
  }, [refresh, notificationTab]);

  const deleteNotification = async (_id) => {
    try {
      const res = await deleteNotificationAPI(_id);
      const _data = res.data;
      let random = Math.random();
      setRefresh(random);
      console.log("delete data", _data.status, random);
      // setRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnEdit = (item) => {
    console.log(item);
    navigation("/push-notification/details", "");
  };

  function triggerData(value) {
    console.log("value", value);
    if (value === "triggerNotification") {
      fetchNotifications("now");
    } else if (value === "scheduleNotification") {
      fetchNotifications("later");
    }
  }

  function spinnerLoader() {
    return (
      <div className="mt-5 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return !loader ? (
    <React.Fragment>
      <React.Fragment>
        {/* <NavHeader/> */}
        <div className="screen-wrap">
          <Container>
            <BreadCrumb data={breadcrumb}></BreadCrumb>
            <div className="screen-view-wrap">
              <div className="screen-view-row no-border">
                <Row>
                  <Col lg={6} sm={12} className="d-flex">
                    <div className="screen-title-wrap d-flex align-self-center">
                      <div className="text-wrap">
                        <p className="subtitle1">Push Notifications</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className="btn-wrap d-flex justify-content-end">
                      <Btn
                        variant="secondary"
                        size={"lg"}
                        label="Go Back"
                        onClick={() => navigation("/home", "")}
                      />
                      <Btn
                        variant="primary"
                        size={"lg"}
                        label="Add New"
                        onClick={() => {
                          navigation("/push-notification/add", "");
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-view-row no-border">
                <div className="tabs-wrap">
                  <Nav
                    variant="tabs"
                    className="text-uppercase"
                    defaultActiveKey={notificationTab}
                    onSelect={(eventKey) => {
                      setNotificationsTab(eventKey);
                      // triggerData(eventKey);
                    }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="triggerNotification">
                        Triggered Notifications
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="scheduleNotification">
                        Scheduled Notifications
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>

              {!isLoading ? (
                <React.Fragment>
                  <div className="screen-view-row no-border">
                    <Row>
                      <Col sm={12}>
                        <Legend label="Listing" />
                      </Col>
                    </Row>
                  </div>

                  <div className="screen-view-row">
                    <Row>
                      <Col sm={12}>
                        {notificationTab === "triggerNotification" && (
                          <React.Fragment>
                            <div className="d-flex flex-wrap">
                              {notificationList.length > 0 ? (
                                notificationList.map((item, index) => {
                                  return (
                                    <div
                                      className="feature-content-row"
                                      key={index}
                                    >
                                      <FeatureContentCard
                                        title={item.title}
                                        subTitle={parse(item.description)}
                                        description={
                                          <React.Fragment>
                                            <div className="text-wrap">
                                              <p className="subtitle2">
                                                <strong>
                                                  For :{" "}
                                                  {item.notification_recipient_type ===
                                                  "all"
                                                    ? "All"
                                                    : "Selected Users"}
                                                </strong>{" "}
                                              </p>
                                            </div>
                                            <div className="text-wrap">
                                              <p className="subtitle2 title">
                                                <strong>
                                                  Created :{" "}
                                                  {moment
                                                    .tz(
                                                      item.created_at,
                                                      "US/Mountain"
                                                    )
                                                    .format(
                                                      "MMM D, YYYY, hh:mm a"
                                                    )}
                                                </strong>
                                              </p>
                                            </div>
                                            <div className="text-wrap">
                                              <p className="subtitle2 title">
                                                <strong>
                                                  Status : Sent on{" "}
                                                  {moment
                                                    .tz(
                                                      item.schedule_time,
                                                      "US/Mountain"
                                                    )
                                                    .format(
                                                      "MMM D, YYYY, hh:mm a"
                                                    )}
                                                </strong>
                                              </p>
                                            </div>
                                            {/* <div className="text-wrap d-flex">
                                          <p className="subtitle2 title">
                                            <strong>Status :</strong> Sent On{" "}
                                            {moment(item.created_at).format(
                                              "MMM D, YYYY"
                                            )}
                                          </p>
                                        </div> */}
                                          </React.Fragment>
                                        }
                                        actions={
                                          <React.Fragment>
                                            <Btn
                                              variant="secondary"
                                              icon={
                                                <span className="icon-Delete-2"></span>
                                              }
                                              onClick={() => {
                                                setModalDelete(true);
                                                setNotificationId(item._id);
                                              }}
                                            />
                                          </React.Fragment>
                                        }
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <div>
                                  <p>
                                    <p>No Triggered Notifications Available</p>
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="d-flex justify-content-end">
                              <Paginator />
                            </div>
                          </React.Fragment>
                        )}
                        {notificationTab === "scheduleNotification" && (
                          <React.Fragment>
                            <div className="d-flex flex-wrap">
                              {notificationList.length > 0 ? (
                                notificationList.map((item, index) => {
                                  return (
                                    <div
                                      className="feature-content-row"
                                      key={index}
                                    >
                                      <FeatureContentCard
                                        title={item.title}
                                        subTitle={parse(item.description)}
                                        description={
                                          <React.Fragment>
                                            <div className="text-wrap">
                                              <p className="subtitle2">
                                                <strong>
                                                  For :{" "}
                                                  {item.notification_recipient_type ===
                                                  "all"
                                                    ? "All"
                                                    : "Selected Users"}
                                                </strong>{" "}
                                              </p>
                                            </div>
                                            <div className="text-wrap">
                                              <p className="subtitle2 title">
                                                <strong>
                                                  {" "}
                                                  Created :{" "}
                                                  {moment
                                                    .tz(
                                                      item.created_at,
                                                      "US/Mountain"
                                                    )
                                                    .format(
                                                      "MMM D, YYYY, hh:mm a"
                                                    )}
                                                </strong>
                                              </p>
                                            </div>
                                            <div className="text-wrap">
                                              <p className="subtitle2 title">
                                                <strong>
                                                  {" "}
                                                  Status : Schedule on{" "}
                                                  {moment
                                                    .tz(
                                                      item.schedule_date,
                                                      "US/Mountain"
                                                    )
                                                    .format(
                                                      "MMM D, YYYY, hh:mm a"
                                                    )}
                                                </strong>
                                              </p>
                                            </div>
                                            {/* <div className="text-wrap d-flex">
                                          <p className="subtitle2 title">
                                            <strong>Status :</strong> Sent On{" "}
                                            {moment(item.created_at).format(
                                              "MMM D, YYYY"
                                            )}
                                          </p>
                                        </div> */}
                                          </React.Fragment>
                                        }
                                        actions={
                                          <React.Fragment>
                                            <Btn
                                              variant="secondary"
                                              icon={
                                                <span className="icon-Edit"></span>
                                              }
                                              onClick={() => {
                                                navigation(
                                                  "/push-notification/edit/" +
                                                    item._id,
                                                  item
                                                );
                                              }}
                                            />
                                            <Btn
                                              variant="secondary"
                                              icon={
                                                <span className="icon-Delete-2"></span>
                                              }
                                              onClick={() => {
                                                setNotificationId(item._id);
                                                setModalDelete(true);
                                              }}
                                            />
                                          </React.Fragment>
                                        }
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <div>
                                  <p>No Scheduled Notifications Available</p>
                                </div>
                              )}
                            </div>
                            <div className="d-flex justify-content-end">
                              <Paginator />
                            </div>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Dialog
                      title={"Delete Notification"}
                      description={
                        <p>
                          "Are you sure you want to delete the selected
                          notification?"
                        </p>
                      }
                      centered={true}
                      show={modalDelete}
                      close={(val) => {
                        setModalDelete(val);
                        console.log(val, "delete");
                      }}
                      actions={
                        <div className="btn-wrap d-flex justify-content-end">
                          <Btn
                            variant="secondary"
                            label="No"
                            onClick={() => {
                              setModalDelete(false);
                            }}
                          />
                          <Btn
                            variant="primary"
                            label="Yes"
                            onClick={() => {
                              setModalDelete(false);
                              deleteNotification(notificationId);
                            }}
                          />
                        </div>
                      }
                    />
                  </div>
                </React.Fragment>
              ) : (
                spinnerLoader()
              )}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </React.Fragment>
  ) : (
    spinnerLoader()
  );
};

export default PushNotificationList;
