import React, { useEffect, useState } from "react";
import { Redirect, Link, useHistory } from "react-router-dom";

// framework
import { Container, Row, Col, Card, Form, Image } from "react-bootstrap";

// plugin
import { ErrorMessage, Formik } from "formik";
import axios from "axios";

// styles
import "./login.scss";

// assets
import { GLOBAL_ICONS } from "../../../assets/icons/global-icons";
import CircularBack from "../../../assets/icons/circular-back.png";

// components
import { Btn, IconButton, Input } from "../../../components";

// utils
import { setSession, isAuthenticated, setUserDetails } from "../../../utils/helpers/Authentication";
import loginApi from "../../../utils/helpers/API/USER_API";

// navigations
// import { navigate } from '../../../navigation/navigation-service';

const Login = () => {
    // useEffect(() => {
    //   console.log("USER_API", localStorage.getItem("@authToken"));
    //   // localStorage.setItem("mytime", Date.now());
    // }, []);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loginSuccess, setLoginSuccess] = useState();

    const history = useHistory();

    // login
    const login = () => {
        window.location.reload();
        history.push({
            pathname: "/home",
        });
    };

    const handleSubmit = async (data) => {
        setErrorMessage(null);

        setUserDetails(data.username);
        console.log("data", data);
        data["platform"] = "WEB";

        //Login Api
        const authorize = async () => {
            try {
                const response = await loginApi(data); //USER_API.LOGIN

                if (response) {
                    let data = response.data;
                    console.log("API response data:", data);

                    if (data.status === "success") {
                        if (data.user_type === "admin") {
                            console.log("Account has been found!");
                            setSession(data.access_token);
                            login();
                        } else {
                            setErrorMessage("Access Denied");
                        }
                    } else {
                        setErrorMessage(data.message);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        authorize();
    };

    useEffect(() => {
        if (isAuthenticated()) {
            history.push({ pathname: "/home" });
        }
    }, []);

    return (
        <div className="screen-wrap login-screen-wrap">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="login-wrap">
                            <div className="login-card-wrap">
                                <Card>
                                    <div className={`login-card-body login-action-body ${!showForgotPassword && "show"}`}>
                                        <Card.Body>
                                            <Card.Title className="text-wrap">
                                                <p className="subtitle1">CMS Login</p>
                                            </Card.Title>
                                            <Formik
                                                initialValues={{
                                                    username: "",
                                                    password: "",
                                                }}
                                                validate={(values) => {
                                                    const errors = {};
                                                    // console.log('Validate Values:', values, ' Errors:', errors);
                                                    if (!values.username) {
                                                        errors.username = "Required";
                                                    }
                                                    if (!values.password) {
                                                        errors.password = "Required";
                                                    }
                                                    return errors;
                                                }}
                                                onSubmit={(values) => {
                                                    console.log("submit Value", values);
                                                    handleSubmit(values);
                                                }}
                                            >
                                                {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                                    <React.Fragment>
                                                        <Form className="form-wrap">
                                                            <Input
                                                                placeholder="Username"
                                                                type="input"
                                                                id="username"
                                                                // onChange={(data) => {console.log(data.target.value, "input")}}
                                                                defaultValue={values.username}
                                                                onChange={(event) => {
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                }}
                                                                isInvalid={errors.username && touched.username && errors.username}
                                                                helperText={errors.username && touched.username && errors.username}
                                                            />
                                                            <Input
                                                                placeholder="Password"
                                                                type={showPassword ? "input" : "password"}
                                                                id="password"
                                                                defaultValue={values.password}
                                                                onChange={(event) => {
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                }}
                                                                isInvalid={errors.password && touched.password && errors.password}
                                                                helperText={errors.password && touched.password && errors.password}
                                                            />
                                                            <Form.Group className="form-group" controlId="show-password">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Show Password"
                                                                    defaultChecked={showPassword}
                                                                    onChange={(event) => setShowPassword(!showPassword)}
                                                                />
                                                            </Form.Group>
                                                            {/* <Form.Group className="form-group d-flex align-items-end flex-column">
																	<Btn 
																			variant="link"
																			label="Forgot Password?"
																			style={"text-capitalize"}
																			onClick={() => {setShowForgotPassword(true)}}
																	/>
															</Form.Group> */}
                                                            {errorMessage && (
                                                                <div className="d-grid mb-3">
                                                                    <p className="text-danger">{errorMessage}</p>
                                                                </div>
                                                            )}

                                                            <div className="d-grid">
                                                                <Btn
                                                                    variant="primary"
                                                                    label="Login"
                                                                    onClick={() => {
                                                                        handleSubmit();
                                                                    }}
                                                                    loader={true}
                                                                />
                                                            </div>
                                                        </Form>
                                                    </React.Fragment>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                    </div>
                                    {/*  <div className={`login-card-body forgot-action-body ${ showForgotPassword && 'show'}`}>
										<Card.Body>
											<IconButton
												src={CircularBack}
												onClick={() => {setShowForgotPassword(false)}}
											/>
											<Card.Title className="text-wrap">
												<p className="subtitle1">Forgot Password?</p>
												<p className="subtitle2">Please enter your Email Address and click on 'Submit'</p>
											</Card.Title>
											<Form className="form-wrap">
												<Input
													placeholder="Email Id."
													type="input"
													id="forgot-password"
													onChange={(data) => {console.log(data.target.value, "input")}}
													style="form-underline"
												/>
												<div className="d-grid">
													<Btn 
														variant="primary"
														label="Submit"
														onClick={() => {}}
													/>
												</div>
											</Form>
										</Card.Body>
									</div> */}
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
