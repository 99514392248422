import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Nav, Row, Col, NavDropdown } from "react-bootstrap";

// style
import "./nav-header.scss";

// assets
import { GLOBAL_IMAGES } from '../../assets/images/global-images';

// utils
import { redirectToLogin } from "../../utils/helpers/Authentication";



const NavHeader = (props) => {

	const [navTitle, setNavTitle] = useState("");
	const [navData, setNavData] = useState(props.navData);
	const [isHeaderActive, setIsHeaderActive] = useState(false);

	// navigation 
	const location = useLocation();
	const history = useHistory();
	const navigation = (href, params) => {
		history.push({
			pathname: href,
			param: params
		});
	}

	useEffect(() => {

		let navName = location.pathname.split("/")[1];
		// console.log(navName, "navName", navData);
		// if (navName == "") {
		// 	setIsHeaderActive(false)
		// } else (
		// 	setIsHeaderActive(true)
		// )

		let navObj = navData.find((obj, index) => obj.nav == navName);
		// console.log(navObj, "navObj");

		if (navObj != undefined) {
			setNavTitle(navObj.title)
		} else {
			setNavTitle("")
		}

	}, [location])


	const handleNavChange = (eventKey) => {
		console.log(eventKey, "eventKey")
		if (eventKey != "Logout") {
			let navObj = navData.find((obj, index) => obj.title == eventKey);
			navigation(navObj.link, '')
		} else {
			// navigation('/', '')
			redirectToLogin()
		}
		setNavTitle(eventKey)
	};



	return (
		// isHeaderActive && (
			<header>
				<Nav activeKey={navTitle} onSelect={handleNavChange}>
					<Container>
						<Row>
							<Col xs={3}>
								<div className="logo-wrap">
									<img src={GLOBAL_IMAGES.Logo}></img>
								</div>
							</Col>
							<Col xs={9}>
								<div className="nav-wrap">
									<NavDropdown title="Admin">
										{/* <NavDropdown.Item eventKey="Change Password">Change Password</NavDropdown.Item> */}
										<NavDropdown.Item eventKey="Logout">Logout</NavDropdown.Item>
									</NavDropdown>
									{/* <NavDropdown title={navTitle} className="dropdown-nav-wrap">
										{
											props.navData.map((item, index) => {
												return (
													item.isActive && <NavDropdown.Item key={index} eventKey={item.title}>{item.title}</NavDropdown.Item>
												)
											})
										}
									</NavDropdown> */}
								</div>
							</Col>
						</Row>

					</Container>
				</Nav>
			</header>
		// )
	)
}

/**
 * logo: provide logo source
 * navData: show list of naviations
 */

NavHeader.propTypes = {
  logo: PropTypes.string,
  navData: PropTypes.array,
};

NavHeader.defaultProps = {
  logo: GLOBAL_IMAGES.Logo,
  navData: null,
};

export default NavHeader;
